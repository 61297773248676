.all-comments-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

@media (prefers-color-scheme: dark) {
  .all-comments-container {
    background-color: #212529;
  }
}
