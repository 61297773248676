.reader-container {
  min-height: 100%;
  height: 90vh;
  width: 100%;
  display: flex;
  flex: 1;
}

.reader-content {
  height: auto;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0.5rem 0.75rem 0.5rem;
  box-shadow: 0 0 0.25rem 0.0625rem #2a6f97;
  border-radius: 5px;
  flex: 1;
  overflow-y: auto;
}

.reader-content::-webkit-scrollbar {
  width: 0;
}

@media (prefers-color-scheme: light) {
  .reader-container {
    background-color: #dee2e6;
  }

  .reader-content {
    background-color: #f8f9fa;
  }
}

@media (prefers-color-scheme: dark) {
  .reader-container {
    background-color: #343a40;
  }

  .reader-content {
    background-color: #343a40;
  }
}
