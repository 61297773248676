.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 1rem);
  height: calc(10vh - 2px); /* -2 px for the border */
  padding: 0 0.5rem 0 0.5rem;
  position: sticky;
  top: 0;
}

.header-title {
  margin: 0 2rem 0 0.5rem;
  font-weight: 900;
  font-size: 2.75rem;
  cursor: pointer;
  text-shadow: 1px 0px;
}

.header-feed-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  overflow: scroll;
  padding-inline: 0 0;
  margin-block: 0 0;
  margin-inline: 0 0;
  gap: 0.5rem;
}

.header-feed-list::-webkit-scrollbar {
  display: none;
}

.header-link {
  height: 3rem;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  flex: 1;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.35rem;
  border-radius: 5px;
}

.header-link-icon {
  /* width: 1.5rem;
  height: 1.5rem; */
  width: 100%;
  height: 100%;
}

.header-link-text {
  display: none;
}

@media (prefers-color-scheme: light) {
  .header-container {
    background-color: #f8f9fa;
    border-bottom: 2px solid #ced4da;
  }
  .header-link {
    color: #212529;
  }
  .header-link:active {
    box-shadow: inset 0 0 4px 2px #a9d6e5df;
    -webkit-tap-highlight-color: transparent;
  }
  .header-link:hover {
    background-color: #a9d6e5;
  }
}

@media (prefers-color-scheme: dark) {
  .header-container {
    background-color: #212529;
    border-bottom: 2px solid #adb5bd;
  }
  .header-link {
    color: #f8f9fa;
  }
  .header-link:active {
    box-shadow: inset 0 0 4px 2px #2a6f97;
    -webkit-tap-highlight-color: transparent;
  }
  .header-link:hover {
    background-color: #014f86;
  }
}

/* Common for Medium to Large Screens */
@media screen and (min-width: 768px) {
  .header-link:active {
    box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
    -webkit-tap-highlight-color: transparent;
  }

  .header-link:hover .header-link-text {
    display: block;
    font-size: 0.75rem;
  }
}
