/* BOTTOM MESSAGE */

.bottom-message-text {
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 1.5rem 0rem 1.5rem 0rem;
  color: #f8f9fa;
  font-weight: 800;
  font-size: 1.25rem;
}

/* NO COMMENTS */

.no-comments-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.no-comments-text {
  margin: 0 0 0 0;
  font-size: 1.5rem;
  font-weight: 400;
}

/* SHOW CHILDREN BUTTON */

.show-children-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  flex: 1;
  cursor: pointer;
}

.show-children-text {
  margin: 0 0 0 0;
  font-size: 0.65rem;
  font-weight: 200;
}

/* NO SELECTED POST */
.no-selected-post-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bubble-icon {
  height: 6rem;
  width: 6rem;
}

.no-selected-post-text {
  font-weight: 800;
  font-size: 2rem;
  margin: 0 0 0 0;
}

@media (prefers-color-scheme: light) {
  .bottom-message-container {
    background: rgb(248, 249, 250);
    background: linear-gradient(
      180deg,
      rgba(248, 249, 250, 1) 0%,
      rgba(169, 214, 229, 1) 25%,
      rgba(70, 143, 175, 1) 50%,
      rgba(1, 73, 124, 1) 75%,
      rgba(1, 42, 74, 1) 100%
    );
  }

  .show-children-container {
    border-bottom: 0.0625rem solid #ced4da;
  }

  .no-selected-post-container {
    background-color: #dee2e6;
  }

  .bubble-icon,
  .no-selected-post-text {
    color: #6c757d;
  }

  .no-comments-text {
    color: #014f86;
  }
}

@media (prefers-color-scheme: dark) {
  .bottom-message-container {
    background: rgb(52, 58, 64);
    background: linear-gradient(
      180deg,
      rgba(52, 58, 64, 1) 0%,
      rgba(70, 143, 175, 1) 25%,
      rgba(70, 143, 175, 1) 50%,
      rgba(1, 73, 124, 1) 75%,
      rgba(1, 42, 74, 1) 100%
    );
  }

  .show-children-container {
    border-bottom: 0.0625rem solid #6c757d;
  }

  .no-selected-post-container {
    background-color: #343a40;
  }

  .bubble-icon,
  .no-selected-post-text {
    color: #adb5bd;
  }

  .no-comments-container {
    background-color: #212529;
  }

  .no-comments-text {
    color: #468faf;
  }
}
