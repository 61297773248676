.feed-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow-y: auto;
}

.feed-container::-webkit-scrollbar {
  width: 0;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.infinite-scroll::-webkit-scrollbar {
  width: 0;
}

@media (prefers-color-scheme: light) {
  .feed-container {
    background-color: #dee2e6;
  }
}

@media (prefers-color-scheme: dark) {
  .feed-container {
    background-color: #343a40;
  }
}
