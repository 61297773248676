.postcard-container {
  /* border: 1px solid #212529; */
  margin: 0.75rem 0.5rem 0.75rem 0.5rem;
  padding: 0.125rem 0rem 0.125rem 0rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* x-offset | y-offset | blur radius | spread radius | colour */
  /* box-shadow: -0.125rem 0.125rem 0.25rem 0rem #212529; */

  cursor: pointer;
}

.postcard-title {
  flex-grow: 1;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  word-wrap: break-word;
  text-align: left;
  font-weight: 600;
  letter-spacing: 0.0125rem;
}

.postcard-info-container {
  margin: 0rem 0.5rem 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.postcard-info-piece {
  display: flex;
  /* Not huge fan of but works better than other options */
  font-size: 0.6rem;
  font-weight: 300;
  flex-direction: row;
  align-items: center;
  gap: 0.125rem;
}

.postcard-info-icon {
  color: #adb5bd;
  width: 0.75rem;
  height: 0.75rem;
}

@media (prefers-color-scheme: light) {
  .postcard-container {
    background-color: #f8f9fa;
    border: 1px solid #adb5bd;
  }

  .postcard-container:hover {
    border: 1px solid #61a5c2;
    box-shadow: 0 0 0.25rem 0.125rem #ffd000;
  }

  .postcard-info-piece > span {
    color: #ff7b00;
  }
}

@media (prefers-color-scheme: dark) {
  .postcard-container {
    background-color: #212529;
    border: 1px solid #6c757d;
  }

  .postcard-container:hover {
    border: 1px solid #ffaa00;
    box-shadow: 0 0 0.25rem 0.125rem #ffaa00;
  }

  .postcard-title {
    color: #e9ecef;
  }

  .postcard-info-piece > span {
    color: #ffa200;
  }
}
