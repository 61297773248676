.App {
  height: 100%;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* color: #212529;
  background-color: #f8f9fa; */
}

@media (prefers-color-scheme: light) {
  .App {
    color: #212529;
    background-color: #f8f9fa;
  }
}

@media (prefers-color-scheme: dark) {
  .App {
    color: #f8f9fa;
    background-color: #212529;
  }
}
