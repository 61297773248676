@import url("./fonts/inter.css");

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* PHONES */
@media screen and (max-width: 767px) {
  html,
  body {
    font-size: 16px;
  }
}

/* TABLETS */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  html,
  body {
    font-size: 16px;
  }
}

/* COMPUTERS */
@media screen and (min-width: 1024px) {
  html,
  body {
    font-size: 18px;
  }
}

/* TODO: add size in between */

@media screen and (min-width: 2560px) {
  html,
  body {
    font-size: 36px;
  }
}

#root {
  height: 100%;
}
