.depth-bar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.75rem;
}

.depth-bar {
  height: 85%;
  width: 0.0625rem;
}
