.spinner-container {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.spinner {
  border: 1rem solid #2c7da0;
  border-top: 1rem solid #61a5c2;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  /* p1 - init accel | p2 - init y change | p3 - second point decel | p4 - second point y change  */
  animation: spin 2s cubic-bezier(0.5, 0, 0.1, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
