.content-container {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow-y: auto;
}

.content-container::-webkit-scrollbar {
  width: 0;
}

.mobile-container {
  width: 100%;
  display: none;
}

.web-container {
  width: 100%;
  display: none;
}

/* FOR PHONES */
@media screen and (max-width: 767px) {
  .mobile-container {
    display: flex;
  }
}

/* FOR TABLETS */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .web-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .feed-column {
    flex: 1;
  }

  .reader-column {
    flex: 2;
  }
}

@media screen and (min-width: 1024px) {
  .web-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .feed-column {
    flex: 1;
  }

  .reader-column {
    flex: 3;
  }
}

@media (prefers-color-scheme: light) {
  .content-container {
    background-color: #dee2e6;
  }
}

@media (prefers-color-scheme: dark) {
  .content-container {
    background-color: #343a40;
  }
}
