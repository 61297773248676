.comment-chain-container {
  display: flex;
  flex-direction: column;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
}

.comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  white-space: normal;
  gap: 0.75rem;
  width: 100%;
}

.comment-content {
  max-width: 100%;
  width: 100%;
  height: auto;
  white-space: normal;
  margin: 0.75rem 0rem 0.75rem 0rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.75rem;
}

.comment-content > span {
  font-style: italic;
  font-weight: 100;
  font-size: 0.75rem;
  align-self: flex-start;
}

.comment-text,
pre,
code,
p,
a {
  font-weight: 300;
  font-size: 0.75rem;
  text-align: left;
  margin: 0 0 0 0;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment-author-date {
  font-weight: 400;
  font-size: 0.65rem;
}

.comment-children-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  font-weight: 300;
  font-size: 0.5rem;
}

.comment-children-icon {
  width: 0.5rem;
  height: 0.5rem;
}

.comment-children-container {
  display: flex;
  flex-direction: column;
}

.comment-children {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (prefers-color-scheme: light) {
  .comment-container {
    border-bottom: 0.0625rem solid #ced4da;
  }
  .comment-author-date {
    color: #adb5bd;
  }

  .comment-author-date span {
    color: #61a5c2;
  }
  .comment-children-icon {
    color: #adb5bd;
  }

  a {
    color: #2a6f97;
  }
}

@media (prefers-color-scheme: dark) {
  .comment-container {
    border-bottom: 0.0625rem solid #6c757d;
  }
  .comment-chain-container {
    background-color: #212529;
  }
  .comment-author-date {
    color: #adb5bd;
  }

  .comment-author-date span {
    color: #61a5c2;
  }

  .comment-children-icon {
    color: #adb5bd;
  }

  a {
    color: #a9d6e5;
  }
}
