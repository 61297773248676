.post-container {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.post-main {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

.post-title {
  font-size: 1rem;
  font-weight: 800;
  text-align: left;
  margin: 0rem 0rem 0rem 0rem;
  hyphens: auto;
}

.post-text {
  font-weight: 300;
  font-size: 0.75rem;
  text-align: left;
  margin: 0 0 0 0;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-main-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.post-main-info {
  font-size: 0.75rem;
  font-weight: 400;
}

.post-main-link {
  font-size: 0.65rem;
  font-weight: 200;
  font-style: italic;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post-main-link-icon {
  width: 0.75rem;
  height: 0.75rem;
}

/* --- */

.post-divider {
  border: 0.0625rem solid #dee2e6;
  margin: 0rem 0rem 0rem 0rem;
}

/* --- */

.post-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0rem 0rem 0rem 0rem;
}

.post-bottom-info {
  font-size: 0.75rem;
  font-weight: 300;
  flex: 1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.post-bottom-info:last-child {
  cursor: pointer;
}

/* .post-bottom-info-icon {
  color: #adb5bd;
  width: 1rem;
  height: 1rem;
} */

.post-bottom-info:nth-child(n + 2) {
  border-left: 0.0625rem solid #dee2e6;
}

.copied {
  color: #61a5c2;
}

@media (prefers-color-scheme: light) {
  .post-container {
    /* box-shadow: 0 3px 3px hsla(210, 11%, 15%, 1); */
    border-bottom: 3px solid hsla(29, 100%, 50%, 0.5);
  }

  .post-title {
    color: #343a40;
  }

  .post-main-info {
    color: #adb5bd;
  }

  .post-main-info-author {
    color: #ff7b00;
  }

  .post-main-link {
    color: #61a5c2;
  }

  .post-main-link-icon {
    color: #a9d6e5;
  }
}

@media (prefers-color-scheme: dark) {
  .post-container {
    background-color: #212529;
    /* border-bottom: 3px solid hsla(210, 14%, 89%, 1); */
    border-bottom: 3px solid hsla(29, 100%, 50%, 0.5);
  }

  .post-title {
    color: #e9ecef;
  }
  .post-main-info {
    color: #adb5bd;
  }

  .post-main-info-author {
    color: #ffa200;
  }

  .post-main-link {
    color: #a9d6e5;
  }

  .post-main-link-icon {
    color: #a9d6e5;
  }
}

@media screen and (min-width: 1024px) {
  .post-main-link-icon {
    width: 1rem;
    height: 1rem;
  }
}
